html[data-theme='Light'],
html {
  /* FONT */
  --font-family: Roboto, sans-serif;
  --font-size-2lg: 22px;
  --font-size-lg: 18px;
  --font-size-md: 14px;
  --font-size-sm: 12px;

  /* COLORS */
  --color-text: #000;
  --color-text-inverse: #fff;
  --color-icon: #000;
  --color-main-1: #5c2fff;
  --color-main-2: #f5500f;
  --color-secondary: #9394a8;
  --color-neutral-1: #ffffff;
  --color-neutral-2: #f5f4fa;
  --color-additional-1: #d250e6;
  --color-additional-2: #7c73fc;
  --color-additional-3: #fe784b;
  --color-additional-4: #d8473a;
  --color-additional-5: #2daa5f;
  --color-additional-6: #0255fa;
  --color-additional-7: #ffc836;

  --color-success: #2daa5f;
  --color-failed: #d8473a;
  --color-info: #ffc836;

  /* BUTTON */
  --buttonMain-textColor: var(--color-neutral-1);
  --buttonMain-backgroundColor: var(--color-main-1);
  --buttonMain-hover-backgroundColor: #3c14d2;
  --buttonMain-disabled-textColor: #ad97ff;
  --buttonSecondary-textColor: #2b2a35;
  --buttonSecondary-backgroundColor: var(--color-neutral-1);
  --buttonSecondary-hover-backgroundColor: #e9e7f4;
  --buttonSecondary-disabled-textColor: var(--color-secondary);
  --buttonAlert-textColor: #fff;
  --buttonAlert-backgroundColor: #f40000;
  --buttonAlert-hover-backgroundColor: #920000;
  --buttonAlert-disabled-textColor: #b6b4c0;

  /* DROPDOWN */
  --dropdown-backgroundColor: #eceaf5;
  --dropdown-labelBackgroundColor: #e1dceb;
  --dropdown-borderColor: transparent;
  --dropdown-placeholderTextColor: var(--color-secondary);
  --dropdown-focusedBorderColor: var(--color-main-1);
  --dropdown-focusedShadowColor: var(--color-main-1);
  --dropdown-panelBackgroundColor: #fff;
  --dropdown-panelBorderColor: transparent;
  --dropdown-itemTextColor: #2b2a35;
  --dropdown-selectedItemTextColor: #fff;
  --dropdown-selectedItemBackgroundColor: var(--color-main-1);
  --dropdown-hoveredItemBackgroundColor: #e2dcec;

  /* DROPDOWN MULTISELECT */
  --dropdownMultiselect-inputBackgroundColor: #eceaf5;
  --dropdownMultiselect-labelBackgroundColor: #e1dceb;
  --dropdownMultiselect-panelBackgroundColor: #fff;
  --dropdownMultiselect-hoveredItemBackgroundColor: #e2dcec;

  /* TEXT INPUT */
  --textInput-backgroundColor: #eceaf5;
  --textInput-labelBackgroundColor: #e1dceb;
  --textInput-placeholderColor: var(--color-secondary);
  --textInput-textColor: #2b2a35;
  --textInput-hoveredBackgroundColor: #c3bece;
  --textInput-errorColor: var(--color-additional-4);
  --textInput-focusBorderColor: var(--color-main-1);

  /* NUMBER INPUT */
  --numberInput-labelBackgrounColor: #e1dceb;
  --numberInput-inputBackgroundColor: #eceaf5;

  /* SWITCHER */
  --switcher-offBackgroundColor: var(--color-secondary);
  --switcher-offTriggerColor: #2b2a35;
  --switcher-onBackgroundColor: #fb6023;
  --switcher-onTriggerColor: #ffa582;

  /* CHECKBOX */
  --checkbox-backgroundColor: #eceaf5;
  --checkbox-tickColor: #5c2fff;

  /* TABLE */
  --table-backgroundColor: #fff;
  --table-color: var(--color-text);
  --table-fontSize: 1.03rem;
  --table-cellBorderColor: rgba(29, 38, 61, 0.5);
  --table-hoverRowBackgroundColor: var(--color-neutral-2);

  /* TEXTAREA */
  --textArea-borderColor: var(--color-neutral-2);
  --textArea-backgroundColor: var(--color-neutral-2);

  /* TABS */
  --tabs-tabBackgroundColor: #eeeeee;
  --tabs-selectedTabBorderColor: var(--color-neutral-2);
  --tabs-selectedTabBackgroundColor: #fff;
  --tabs-selectedTabContentBackgroundColor: var(--color-neutral-1);

  /* STEPPER */
  --stepper-leftSideContentBackgroundColor: #ffffff;
  --stepper-rightSideContentBackgroundColor: #f5f4fa;

  /* RADIO GROUP */
  --radioGroup-emulatorBackgroundColor: #eceaf5;
  --radioGroup-emulatorCheckedBackgroundColor: #5c2fff;

  /* DROPDOWN MENU */
  --dropdownMenu-panelBackgroundColor: #fff;
  --dropdownMenu-panelItemHoverBackgroundColor: #e2dcec;

  /* DATEPICKER */
  --datepicker-labelBackgroundColor: #e1dceb;
  --datepicker-inputBackgroundColor: #eceaf5;

  /* CHIPS */
  --chips-mainBackgroundColor: #ffa582;
  --chips-lightBackgroundColor: #ffdbcd;

  /* TAGS */
  --tags-lightGreyBackgroundColor: #f6f6f6;
}

html[data-theme='Dark'] {
  /* FONT */
  --font-family: Roboto, sans-serif;
  --font-size-2lg: 22px;
  --font-size-lg: 18px;
  --font-size-md: 14px;
  --font-size-sm: 12px;

  /* COLORS */
  --color-text: #fff;
  --color-text-inverse: #000;
  --color-icon: #fff;
  --color-main-1: #5c2fff;
  --color-main-2: #f5500f;
  --color-secondary: #9394a8;
  --color-neutral-1: #0e121f;
  --color-neutral-2: #000810;
  --color-additional-1: #d250e6;
  --color-additional-2: #7c73fc;
  --color-additional-3: #fe784b;
  --color-additional-4: #d8473a;
  --color-additional-5: #2daa5f;
  --color-additional-6: #0255fa;
  --color-additional-7: #ffc836;

  --color-success: #2daa5f;
  --color-failed: #d8473a;

  /* BUTTON */
  --buttonMain-textColor: #fff;
  --buttonMain-backgroundColor: var(--color-main-1);
  --buttonMain-hover-backgroundColor: #3c14d2;
  --buttonMain-disabled-textColor: #ad97ff;
  --buttonSecondary-textColor: #fff;
  --buttonSecondary-backgroundColor: #0f1421;
  --buttonSecondary-hover-backgroundColor: #222b44;
  --buttonSecondary-disabled-textColor: var(--color-secondary);
  --buttonAlert-textColor: #fff;
  --buttonAlert-backgroundColor: #f40000;
  --buttonAlert-hover-backgroundColor: #920000;
  --buttonAlert-disabled-textColor: var(--color-secondary);

  /* DROPDOWN */
  --dropdown-backgroundColor: #0f1421;
  --dropdown-labelBackgroundColor: #080d18;
  --dropdown-borderColor: transparent;
  --dropdown-placeholderTextColor: #4e5c82;
  --dropdown-focusedBorderColor: var(--color-main-1);
  --dropdown-focusedShadowColor: var(--color-main-1);
  --dropdown-panelBackgroundColor: #0f1421;
  --dropdown-panelBorderColor: transparent;
  --dropdown-itemTextColor: #fff;
  --dropdown-selectedItemTextColor: #fff;
  --dropdown-selectedItemBackgroundColor: var(--color-neutral-2);
  --dropdown-hoveredItemBackgroundColor: var(--color-neutral-1);

  /* DROPDOWN MULTISELECT */
  --dropdownMultiselect-inputBackgroundColor: #0f1421;
  --dropdownMultiselect-labelBackgroundColor: #080d18;
  --dropdownMultiselect-hoveredItemBackgroundColor: var(--color-neutral-1);

  /* TEXT INPUT */
  --textInput-backgroundColor: #0f1421;
  --textInput-placeholderColor: #4e5c82;
  --textInput-labelBackgroundColor: #080d18;
  --textInput-textColor: #fff;
  --textInput-hoveredBackgroundColor: #0b1221;
  --textInput-errorColor: var(--color-additional-4);
  --textInput-focusBorderColor: var(--color-main-1);

  /* NUMBER INPUT */
  --numberInput-labelBackgrounColor: #080d18;
  --numberInput-inputBackgroundColor: #0f1421;

  /* SWITCHER */
  --switcher-offBackgroundColor: #0f1421;
  --switcher-offTriggerColor: #1d263d;
  --switcher-onBackgroundColor: var(--color-main-1);
  --switcher-onTriggerColor: #967dff;

  /* CHECKBOX */
  --checkbox-backgroundColor: #0f1421;
  --checkbox-tickColor: #5c2fff;

  /* TABLE */
  --table-backgroundColor: var(--color-neutral-1);
  --table-color: var(--color-text);
  --table-fontSize: 1.03rem;
  --table-cellBorderColor: rgba(29, 38, 61, 0.3);
  --table-hoverRowBackgroundColor: var(--color-neutral-2);

  /* TEXTAREA */
  --textArea-borderColor: var(--color-neutral-2);
  --textArea-backgroundColor: var(--color-neutral-2);

  /* TABS */
  --tabs-tabBackgroundColor: #07090f;
  --tabs-selectedTabBorderColor: var(--color-neutral-2);
  --tabs-selectedTabBackgroundColor: var(--color-neutral-1);
  --tabs-selectedTabContentBackgroundColor: var(--color-neutral-1);

  /* STEPPER */
  --stepper-leftSideContentBackgroundColor: #0e121f;
  --stepper-rightSideContentBackgroundColor: #000810;

  /* RADIO GROUP */
  --radioGroup-emulatorBackgroundColor: #000810;
  --radioGroup-emulatorCheckedBackgroundColor: #5c2fff;

  /* DROPDOWN MENU */
  --dropdownMenu-panelBackgroundColor: #0f1421;
  --dropdownMenu-panelItemHoverBackgroundColor: var(--color-neutral-1);

  /* DATEPICKER */
  --datepicker-labelBackgroundColor: #080d18;
  --datepicker-inputBackgroundColor: #0f1421;

  /* CHIPS */
  --chips-mainBackgroundColor: #ffa582;
  --chips-lightBackgroundColor: #ffdbcd;

  /* TAGS */
  --tags-lightGreyBackgroundColor: #f6f6f6;
}
