@use '@angular/material' as mat;

@import 'air-datepicker/air-datepicker.css';

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

@include mat.all-component-themes($my-theme);

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.knk-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.knk-button-group {
  display: flex;
  gap: 0;
}

.knk-button-group button.knk-button {
  border-radius: 0;
}

.knk-button-group knk-button:first-of-type button.knk-button {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.knk-button-group knk-button:not(:first-of-type) button.knk-button,
knk-button:not(:last-of-type) button.knk-button {
  border-left-width: 0;
}

.knk-button-group knk-button:last-of-type button.knk-button {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.info-toast {
}

.error-toast {
  background-color: var(--color-failed) !important;
  color: var(--color-text-inverse);
}

.success-toast {
  background-color: var(--color-success) !important;
  color: var(--color-text-inverse);
}

.mat-dialog-container {
  padding: 0 !important;
}

.text-2lg {
  font-size: var(--font-size-2lg);
}

.text-lg {
  font-size: var(--font-size-lg);
}

.text-md {
  font-size: var(--font-size-md);
}

.text-sm {
  font-size: var(--font-size-sm);
}

.gap-sm {
  gap: 5px;
}

.gap-md {
  gap: 8px;
}

.gap-lg {
  gap: 16px;
}

.form {
  &-vertical {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
  }

  &-horizontal {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    > * {
      flex-grow: 1;
    }

    > .grow-1\/3 {
      flex-grow: 0.3;
    }
  }
}

.card {
  background-color: var(--color-neutral-1);
  border-radius: 0.5rem;
  padding: 1rem;
}

.container {
  &-vertical {
    display: flex;
    flex-direction: column;
  }

  &-horizontal {
    display: flex;

    &.full-width {
      width: 100%;

      > * {
        flex-grow: 1;
      }
    }

    > .push-right {
      margin-left: auto;
    }

    &.vertical-align-center {
      align-items: center;
    }
  }
}

.air-datepicker-global-container {
  z-index: 1000;
}

.shadow-xl {
  box-shadow:
    0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}

.rounded-md {
  border-radius: 7px;
}
