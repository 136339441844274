@import 'grapesjs/dist/css/grapes.min.css';
@import 'grapick/dist/grapick.min.css';
@import '@event-calendar/core/index.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --space-m: 0.5rem;

  --tui-background-accent-1: #5c2fff;
  --tui-background-accent-1-hover: #3c14d2;
  --tui-text-action: #5c2fff;
  --tui-text-action-hover: #3c14d2;

  --tui-font-heading-1: 500 3.125rem / 3.5rem var(--tui-font-heading);
  --tui-font-heading-2: 500 2.75rem / 3rem var(--tui-font-heading);
  --tui-font-heading-3: 500 2.25rem / 2.5rem var(--tui-font-heading);
  --tui-font-heading-4: 500 1.75rem / 2rem var(--tui-font-heading);
  --tui-font-heading-5: 500 1.5rem / 1.75rem var(--tui-font-heading);
  --tui-font-heading-6: 500 1.25rem / 1.5rem var(--tui-font-heading);
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--color-text);
  font-size: 14px !important;
}

// TODO: deprecated
html[data-theme='Light'] {
  --color-mainBackground: #fff;
}

html[data-theme='Dark'] {
  --color-mainBackground: #000;
}

// TODO remove after disabling gridster
gridster {
  background: transparent !important;
}

h1 {
  color: var(--color-text);
  font: var(--ui-font-title-bold);
  font-size: 2rem;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.75rem;
  line-height: 100%;
  margin: 0;
  margin-bottom: 0.75rem;
  padding: 0;
}

h3 {
  color: var(--color-text);
  font: var(--ui-font-title);
  font-size: 1.3rem;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

h4 {
  color: var(--color-text);
  font: var(--ui-font-title-bold);
  font-size: 0.95rem;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

h5 {
  color: var(--color-text);
  font: var(--ui-font-title);
  font-size: 0.95rem;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

.t-dialog__wrapper_sidebar-dialog {
  justify-content: flex-end !important;
  align-items: stretch !important;

  .t-dialog {
    height: 100vh !important;
    max-height: 100vh !important;
    width: 400px !important;
    max-width: 90vw !important;
    margin: 0 !important;

    border-radius: 0 !important;

    transform: translateX(100%);
    animation: slide-in 0.3s forwards;

    display: flex;
    flex-direction: column;

    .t-dialog__header {
      padding: 16px;
      border-bottom: 1px solid var(--tui-background-neutral-1-hover);
    }

    .t-dialog__content {
      flex-grow: 1;
      overflow-y: auto;
      padding: 16px;
    }

    .t-dialog__footer {
      padding: 16px;
      border-top: 1px solid var(--tui-background-neutral-1-hover);
    }
  }

  &.t-dialog_closing {
    .t-dialog {
      animation: slide-out 0.3s forwards;
    }
  }
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
